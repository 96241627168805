import MoncksSpurImg from "../../../assets/images/projects/moncks-spur"

export const moncksSpurData = [
  {
    name: "intro",
    ImageComponent: MoncksSpurImg,
    textColumn: "left",
    col1: [
      {
        type: "text",
        text: `This project was close to my heart, as it was my family home growing up. `,
      },
      { type: "after", size: "md", afterImg: "intro2After" },
      {
        type: "content-text",
        text: `
        The wall was engineered and had steel portals put in its place so we could have that beautiful open plan living/dining and lounge all looking straight out to the ocean and alps.`,
      },

      { type: "after", size: "lg", afterImg: "intro4After" },
    ],
    col2: [
      { type: "after", size: "lg", afterImg: "intro1After" },
      {
        type: "content-text",
        text: `
        The first thing that we knew had to happen, was to remove the concrete wall in the centre of the top floor. This wall was dated and awkwardly divided the room but was a structural necessity.`,
      },
      { type: "after", size: "sm", afterImg: "intro3After" },

      { type: "after", size: "md", afterImg: "intro5After" },
    ],
  },
  {
    name: "lounge",
    ImageComponent: MoncksSpurImg,
    textColumn: "left",
    col1: [
      {
        type: "text",
        title: "Separate Lounge",
        text: `This also made room for a separate lounge that could be closed off by a large seamless sliding door. `,
      },
      { type: "after", size: "md", afterImg: "lounge1After" },
    ],
    col2: [{ type: "after", size: "lg", afterImg: "lounge2After" }],
  },
  {
    name: "exterior",
    ImageComponent: MoncksSpurImg,
    textColumn: "right",
    col1: [
      { type: "after", size: "lg", afterImg: "exterior1After" },
      { type: "after", size: "md", afterImg: "exterior3After" },
      { type: "after", size: "lg", afterImg: "exterior5After" },
    ],
    col2: [
      {
        type: "text",
        title: "Exterior",
        text: `Re-clad in cedar and plaster system, new roof, new double glazed aluminium windows with green tint, new decks and extensive landscaping and retaining work, the house was like new.`,
      },
      { type: "after", size: "lg", afterImg: "exterior2After" },
      {
        type: "comparison",
        size: "md",
        beforeImg: "exterior8Before",
        beforeImgStyle: null,
        afterImg: "exterior8After",
        afterStyle: null,
      },
      { type: "after", size: "lg", afterImg: "exterior9After" },
    ],
  },
  {
    name: "bedrooms",
    ImageComponent: MoncksSpurImg,
    textColumn: "left",
    col1: [
      {
        type: "text",
        title: "Bedrooms",
        text: `We put new wardrobes in each bedroom to maximise storage. The plush carpet and new double glazed windows made the bedrooms cozy and inviting. `,
      },
      { type: "after", size: "md", afterImg: "bedroom2After" },
      { type: "after", size: "sm", afterImg: "bedroom5After" },

      { type: "after", size: "lg", afterImg: "bedroom4After" },
    ],
    col2: [
      { type: "after", size: "lg", afterImg: "bedroom1After" },
      { type: "after", size: "sm", afterImg: "bedroom3After" },
      { type: "after", size: "md", afterImg: "bedroom6After" },
    ],
  },
  {
    name: "bathrooms",
    ImageComponent: MoncksSpurImg,
    textColumn: "left",
    col1: [
      {
        type: "text",
        title: "Bathrooms",
        text: `There were 3 bathrooms and two separate toilets. The bathrooms were smaller in size so we kept them nice and light and coastal with simplistic patterned tiled feature walls and whitewash wood. <br/><br/>
        We added the Master ensuite in to an existing awkward cupboard and extended the roofline out to give ample head height.`,
      },
      { type: "after", size: "lg", afterImg: "bathroom1After" },
      { type: "after", size: "md", afterImg: "bathroom4After" },
    ],
    col2: [
      { type: "after", size: "lg", afterImg: "bathroom3After" },
      { type: "after", size: "md", afterImg: "bathroom2After" },
    ],
  },
]
