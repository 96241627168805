import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import GatsbyImage from "gatsby-image"

const MoncksSpurImg = props => {
  const { src, className = "" } = props
  const data = useStaticQuery(graphql`
    query {
      bathroom1After: file(
        relativePath: { eq: "projects/moncks-spur/bathroom1After.jpeg" }
      ) {
        childImageSharp {
          fluid(maxHeight: 760, quality: 80) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      bathroom2After: file(
        relativePath: { eq: "projects/moncks-spur/bathroom2After.jpeg" }
      ) {
        childImageSharp {
          fluid(maxHeight: 760, quality: 80) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      bathroom3After: file(
        relativePath: { eq: "projects/moncks-spur/bathroom3After.jpg" }
      ) {
        childImageSharp {
          fluid(maxHeight: 760, quality: 80) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      bathroom4After: file(
        relativePath: { eq: "projects/moncks-spur/bathroom4After.jpeg" }
      ) {
        childImageSharp {
          fluid(maxHeight: 760, quality: 80) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      bedroom1After: file(
        relativePath: { eq: "projects/moncks-spur/bedroom1After.jpeg" }
      ) {
        childImageSharp {
          fluid(maxHeight: 760, quality: 80) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      bedroom2After: file(
        relativePath: { eq: "projects/moncks-spur/bedroom2After.jpeg" }
      ) {
        childImageSharp {
          fluid(maxHeight: 760, quality: 80) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      bedroom3After: file(
        relativePath: { eq: "projects/moncks-spur/bedroom3After.jpeg" }
      ) {
        childImageSharp {
          fluid(maxHeight: 760, quality: 80) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      bedroom4After: file(
        relativePath: { eq: "projects/moncks-spur/bedroom4After.jpeg" }
      ) {
        childImageSharp {
          fluid(maxHeight: 760, quality: 80) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      bedroom5After: file(
        relativePath: { eq: "projects/moncks-spur/bedroom5After.jpeg" }
      ) {
        childImageSharp {
          fluid(maxHeight: 760, quality: 80) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      bedroom6After: file(
        relativePath: { eq: "projects/moncks-spur/bedroom6After.jpg" }
      ) {
        childImageSharp {
          fluid(maxHeight: 760, quality: 80) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      bedroom7After: file(
        relativePath: { eq: "projects/moncks-spur/bedroom7After.jpeg" }
      ) {
        childImageSharp {
          fluid(maxHeight: 760, quality: 80) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      exterior1After: file(
        relativePath: { eq: "projects/moncks-spur/exterior1After.jpg" }
      ) {
        childImageSharp {
          fluid(maxHeight: 1440, quality: 80) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      exterior2After: file(
        relativePath: { eq: "projects/moncks-spur/exterior2After.jpeg" }
      ) {
        childImageSharp {
          fluid(maxHeight: 760, quality: 80) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      exterior3After: file(
        relativePath: { eq: "projects/moncks-spur/exterior3After.jpeg" }
      ) {
        childImageSharp {
          fluid(maxHeight: 760, quality: 80) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      exterior4After: file(
        relativePath: { eq: "projects/moncks-spur/exterior4After.jpeg" }
      ) {
        childImageSharp {
          fluid(maxHeight: 760, quality: 80) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      exterior5After: file(
        relativePath: { eq: "projects/moncks-spur/exterior5After.jpg" }
      ) {
        childImageSharp {
          fluid(maxHeight: 760, quality: 80) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      exterior6After: file(
        relativePath: { eq: "projects/moncks-spur/exterior6After.jpeg" }
      ) {
        childImageSharp {
          fluid(maxHeight: 760, quality: 80) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      exterior7After: file(
        relativePath: { eq: "projects/moncks-spur/exterior7After.jpeg" }
      ) {
        childImageSharp {
          fluid(maxHeight: 760, quality: 80) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      exterior8After: file(
        relativePath: { eq: "projects/moncks-spur/exterior8After.jpeg" }
      ) {
        childImageSharp {
          fluid(maxHeight: 760, quality: 80) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      exterior8Before: file(
        relativePath: { eq: "projects/moncks-spur/exterior8Before.jpg" }
      ) {
        childImageSharp {
          fluid(maxHeight: 760, quality: 80) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      exterior9After: file(
        relativePath: { eq: "projects/moncks-spur/exterior9After.jpeg" }
      ) {
        childImageSharp {
          fluid(maxHeight: 760, quality: 80) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      intro1After: file(
        relativePath: { eq: "projects/moncks-spur/intro1After.jpeg" }
      ) {
        childImageSharp {
          fluid(maxHeight: 760, quality: 80) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      intro2After: file(
        relativePath: { eq: "projects/moncks-spur/intro2After.jpeg" }
      ) {
        childImageSharp {
          fluid(maxHeight: 760, quality: 80) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      intro3After: file(
        relativePath: { eq: "projects/moncks-spur/intro3After.jpeg" }
      ) {
        childImageSharp {
          fluid(maxHeight: 760, quality: 80) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      intro4After: file(
        relativePath: { eq: "projects/moncks-spur/intro4After.jpeg" }
      ) {
        childImageSharp {
          fluid(maxHeight: 760, quality: 80) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      intro5After: file(
        relativePath: { eq: "projects/moncks-spur/intro5After.jpeg" }
      ) {
        childImageSharp {
          fluid(maxHeight: 760, quality: 80) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      lounge1After: file(
        relativePath: { eq: "projects/moncks-spur/lounge1After.jpeg" }
      ) {
        childImageSharp {
          fluid(maxHeight: 760, quality: 80) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      lounge2After: file(
        relativePath: { eq: "projects/moncks-spur/lounge2After.jpeg" }
      ) {
        childImageSharp {
          fluid(maxHeight: 760, quality: 80) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)
  if (!data[src]) {
    console.error("image not found:", src)
    //alert(src)
  }

  //   const eagerImages = ["home1"]
  //  const loading = eagerImages.includes(src) ? "eager" : "lazy"
  const loading = "eager"

  return (
    <GatsbyImage
      {...props}
      fluid={data[src].childImageSharp.fluid}
      className={`img img--${src} ${className}`}
      loading={loading}
    />
  )
}
export default MoncksSpurImg
